.App {
    font-family: sans-serif;
    background-color: #e0e0e0;
    height: 100%;
    min-height: calc(100vh );
}
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
}